/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type AgreementInformation = {
    memberId?: string | null;
    agreementPrice?: number | null;
    organisation?: AgreementInformation.organisation | null;
    agreementType?: string | null;
    agreementGroup?: string | null;
    primarySector?: string | null;
    firstPaymentDate?: string | null;
    priceGroup?: string | null;
}

export namespace AgreementInformation {

    export enum organisation {
        DM = 'DM',
        JA = 'JA',
        DSL = 'DSL',
    }


}